/* rem and em DO NOT depend on html font-size in media queries!
Instead 1em = 1rem = 16px
So, to calculate screen size, size / 16 = em screen size
ex: 1350px screen / 16 = ~84em so media max-width = 84em
then, calc exact by multiplying 16 * 84 = below 1344px */

/*******************************
******* MAX-WIDTH 2000px ******* 
*******************************/
@media (max-width: 125em) {
  .header {
    height: 55rem;
    background-position: 0 25%;
  }
  .heading-primary {
    font-size: 4.4rem;
  }

  .cta-box {
    gap: 5%;
  }
}

/*******************************
******* MAX-WIDTH 1696px ******* 
*******************************/
@media (max-width: 106em) {
  .header {
    height: 50rem;
  }
}

/*******************************
******* MAX-WIDTH 1392px ******* 
*******************************/
@media (max-width: 87em) {
  .header {
    height: 45rem;
    background-position: 0 15%;
  }

  .heading-primary {
    font-size: 3.6rem;
  }
  .modal-img {
    width: 64rem;
    height: 48rem;
  }
  .btn-modal-left {
    margin-right: 1rem;
  }

  .btn-modal-right {
    margin-left: 1rem;
  }
  .btn-modal-close {
    right: -5%;
    top: -3%;
  }
}

/*******************************
******* MAX-WIDTH 1104px ******* 
*******************************/
@media (max-width: 69em) {
  .header {
    height: 42rem;
  }

  .details {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 4rem;
  }

  .details-heading-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .details-subheading {
    align-self: flex-start;
  }

  .card-1 {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    justify-self: center;
  }
  .card-2 {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    justify-self: right;
  }
  .card-3 {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    justify-self: left;
  }

  .details-span {
    text-align: center;
  }

  .cta-box {
    flex-direction: column;
  }

  .modal-img-container {
    transform: scale(0.85, 0.85);
  }

  .btn-modal-left,
  .btn-modal-right {
    height: 7rem;
    width: 3.5rem;
  }

  .btn-modal-left {
    margin-right: -4rem;
  }

  .btn-modal-right {
    margin-left: -4rem;
  }

  .btn-modal-close {
    right: 10%;
    top: 4%;
  }

  .modal {
    transform: translate(-45%, -50%);
  }
}

/*******************************
******* MAX-WIDTH 1024px ******* 
*******************************/
@media (max-width: 64em) {
  .slider-box {
    overflow: scroll;
  }

  .slider-box::-webkit-scrollbar {
    display: none;
  }

  .btn-slider-left,
  .btn-slider-right,
  .slider-dots {
    display: none;
  }
}

/*******************************
******* MAX-WIDTH 896px ******* 
*******************************/
@media (max-width: 56em) {
  .heading-primary {
    max-width: 70%;
    display: flex;
    margin: 0 auto 6.4rem;
    text-align: center;
  }
  .section-details {
    padding: 8rem 0 11.2rem;
  }

  .modal {
    transform: translate(-35%, -50%);
  }

  .modal-img-container {
    transform: scale(0.75, 0.75);
  }

  .btn-modal-left,
  .btn-modal-right {
    height: 7rem;
    width: 3.5rem;
  }

  .btn-modal-left {
    margin-right: -7.5rem;
  }

  .btn-modal-right {
    margin-left: -7.5rem;
  }

  .btn-modal-close {
    right: 19%;
    top: 8%;
  }
}

/*******************************
******* MAX-WIDTH 752px ******* 
*******************************/
@media (max-width: 47em) {
  .header {
    height: 32rem;
  }

  .section-hero {
    padding: 6.4rem 0 8rem;
  }
  .heading-primary {
    margin-bottom: 4.8rem;
  }

  .section-details {
    grid-template-rows: repeat(3, 1fr);
  }

  .details-heading-box {
    align-items: flex-start;
    margin-left: 4rem;
  }

  .card-1 {
    grid-row: 1 / 2;
    margin-right: 10rem;
  }
  .card-2 {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    justify-self: center;
    margin-left: 10rem;
  }
  .card-3 {
    grid-row: 3 / 4;
    grid-column: 1 / 3;
    justify-self: center;
    margin-right: 10rem;
  }

  .details-span {
    display: flex;
    text-align: center;
    margin: 0 1rem;
  }

  .slider-box {
    height: 30rem;
    margin-bottom: 2rem;
  }

  .slider-heading {
    margin-bottom: 4.8rem;
  }

  .slide {
    height: 12rem;
    width: 16rem;
  }

  /* .btn-slider-left,
  .btn-slider-right {
    height: 5rem;
    transform: translateY(70%);
  }

  .slider-dots {
    flex-wrap: wrap;
    justify-content: center;
    bottom: 0%;
  } */

  .contact-section {
    max-width: 35rem;
    max-height: 60rem;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contact-info {
    align-self: center;
  }

  .contact-name {
    width: 29rem;
  }
  .contact-email {
    width: 29rem;
  }

  .contact-phone-number {
    width: 29rem;
  }

  .contact-message {
    max-width: 29rem;
  }
  .arrive-depart {
    column-gap: 2rem;
  }

  .date-arrive,
  .date-depart {
    max-width: 13.5rem;
    max-height: 3.4rem;
  }
  .check-label {
    margin-right: 2rem;
  }
  .modal {
    transform: translate(-38%, -50%);
  }
}

/*******************************
******* MAX-WIDTH 668px ******* 
*******************************/
@media (max-width: 41.75em) {
  /* .slider-dots {
    display: none;
  } */
  .slider-box {
    height: 20rem;
  }
  .slide {
    height: 10.5rem;
    width: 14rem;
  }
  /* .btn-slider-left,
  .btn-slider-right {
    transform: translateY(60%);
    height: 5rem;
    width: 3.2rem;
  }

  .btn-slider-left .btn-icon {
    font-size: 1.8rem;
    margin-right: 0.25rem;
  }
  .btn-slider-right .btn-icon {
    font-size: 1.8rem;
  } */
  .modal {
    transform: translate(-39%, -50%);
  }
}

/*******************************
******* MAX-WIDTH 576px ******* 
*******************************/
@media (max-width: 36em) {
  html {
    font-size: 50%;
  }

  .header {
    height: 25rem;
    background-position: 90% 0;
  }
  .main-nav-link-bold {
    font-size: 1.4rem;
    font-weight: 700;
  }

  .heading-secondary {
    font-size: 3rem;
  }
  .card-1,
  .card-2,
  .card-3 {
    margin: 0;
    justify-self: center;
  }

  .slide {
    height: 9rem;
    width: 12rem;
  }

  .slider {
    margin: 0 3.5rem;
  }

  /* .btn-slider-left,
  .btn-slider-right {
    height: 4rem;
    width: 2.5rem;
    transform: translateY(60%);
  }
  .btn-slider-left .btn-icon,
  .btn-slider-right .btn-icon {
    margin-top: 0.2rem;
  } */
}

/*******************************
******* MAX-WIDTH 464px ******* 
*******************************/
@media (max-width: 29em) {
  .modal {
    transform: translate(-31%, -50%);
  }

  .modal-img-container {
    transform: scale(0.58, 0.58);
  }

  .btn-modal-left {
    margin-right: -13.2rem;
  }

  .btn-modal-right {
    margin-left: -13.2rem;
  }

  .btn-modal-close {
    right: 35%;
    top: 17%;
    transform: scale(0.8, 0.8);
  }

  .slide {
    height: 8.25rem;
    width: 11rem;
  }
}

/*******************************
******* MAX-WIDTH 384px ******* 
*******************************/
@media (max-width: 24em) {
  .modal {
    transform: translate(-27%, -50%);
  }

  .modal-img-container {
    transform: scale(0.49, 0.49);
  }

  .btn-modal-left,
  .btn-modal-right {
    height: 7rem;
    width: 3.5rem;
  }

  .btn-modal-left {
    margin-right: -16rem;
  }

  .btn-modal-right {
    margin-left: -16rem;
  }

  .btn-modal-close {
    right: 43%;
    top: 21%;
    transform: scale(0.8, 0.8);
  }
}

/*******************************
******* MIN-WIDTH 1200px ******* 
*******************************/
/* SETS BACKGROUND WIDTH FOR SCREENS OVER 1200PX */

@media (min-width: 75em) {
  body {
    max-width: 100%;
  }

  .header {
    max-width: 100%;
  }
  .section-hero {
    max-width: 100%;
  }

  .section-slider {
    max-width: 100%;
  }

  .section-cta {
    max-width: 100%;
  }

  .cta-heading-container {
    margin: 0 auto 6.4rem;
    justify-content: center;
  }
  .cta-heading {
    align-self: flex-start;
    margin-left: 30rem;
  }

  .cta-subheading {
    margin-left: 32rem;
  }
}

/*******************************
******* MIN-WIDTH 2000px ******* 
*******************************/
@media (min-width: 125em) {
  .header {
    height: 65rem;
    background-position: 0 25%;
  }

  .heading-primary {
    font-size: 4.4rem;
  }
}
